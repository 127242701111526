import { RegistrationFeeQuote } from "@justraviga/classmanager-sdk";

import { CheckoutItem } from "./CheckoutItem";
import { CheckoutSection } from "./CheckoutSection";
import { itemSubtotalForUser } from "../../../../checkoutUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { useSettings } from "../../../useSettings";

export const RegistrationFeesSummary = ({
  registrationFees,
}: {
  registrationFees: RegistrationFeeQuote[];
}) => {
  const { View } = useGenericComponents();
  const { taxMode } = useSettings("tax");
  return (
    <CheckoutSection heading={"Registration fees"} spacing={"tight"}>
      {registrationFees.map((registrationFee, index) => (
        <View key={index}>
          <CheckoutItem
            text={registrationFee.name}
            amount={itemSubtotalForUser(
              {
                ...registrationFee,
                subtotal: registrationFee.subTotal,
              },
              taxMode,
            )}
          />
        </View>
      ))}
    </CheckoutSection>
  );
};
