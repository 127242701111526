import { MobileDatatableRecordCount } from "@shared/components/datatables/MobileDatatableRecordCount";
import { cn } from "@shared/cssUtils";
import { DatatableContentState, useDatatable } from "@shared/datatable";

import { DatatableFilters } from "@/modules/common/datatable/common/DatatableFilters";
import { MobileSelectButton } from "@/modules/common/datatable/content/mobile/MobileSelectButton";
import { SearchInput } from "@/modules/common/form/SearchInput";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

interface MobileHeaderProps {
  contentState: DatatableContentState;
  totalResults: number;
}

export const MobileHeader = ({
  contentState,
  totalResults,
}: MobileHeaderProps) => {
  const { datatable, setSearch } = useDatatable();

  const additionalActions = datatable.additionalActions;

  return (
    <>
      {/** Title and create button */}
      {datatable.title || datatable.createAction || additionalActions ? (
        <div className={"flex items-center justify-between pb-5"}>
          <div>
            {datatable.title && (
              <h6
                className={cn(
                  "mr-4 line-clamp-1 text-grey-900",
                  datatable.titleSize === "sm"
                    ? "text-heading6-600"
                    : "text-heading5-600",
                )}>
                {datatable.title}
              </h6>
            )}
          </div>

          <div className="flex-end flex gap-2">
            {datatable.navigationAction && (
              <div>
                <ProtectedOverlay
                  permission={datatable.navigationAction.permission}>
                  <IconButton
                    icon={datatable.navigationAction.icon ?? "link"}
                    border={"rounded"}
                    onClick={datatable.navigationAction.onClick}
                    variant={"secondary-fill"}
                    size={datatable.titleSize === "sm" ? "sm" : "lg"}
                  />
                </ProtectedOverlay>
              </div>
            )}

            {datatable.createAction && (
              <div>
                <ProtectedOverlay permission={datatable.permissions?.create}>
                  <IconButton
                    icon={"addOutline"}
                    border={"rounded"}
                    onClick={datatable.createAction}
                    variant={"secondary-fill"}
                    size={datatable.titleSize === "sm" ? "sm" : "lg"}
                  />
                </ProtectedOverlay>
              </div>
            )}

            {additionalActions && (
              <div className={""}>
                <ActionMenu
                  showActionsAsBottomSheetOnMobile={true}
                  header={
                    additionalActions?.title && (
                      <h3 className={"text-heading6-600 text-grey-900"}>
                        {additionalActions.title}
                      </h3>
                    )
                  }
                  trigger={
                    <IconButton
                      icon={"ellipsisHorizontal"}
                      variant={"standard"}
                      role="datatable-additional-actions"
                    />
                  }
                  items={additionalActions.items.map(action => ({
                    title: action.title,
                    onClick: action.onClick,
                    protector: action.protector,
                    permission: action.permission,
                    leftIcon: action.icon,
                    group: action.items
                      ? {
                          items: action.items.map(item => ({
                            title: item.title,
                            onClick: item.onClick,
                            leftIcon: item.icon,
                            permission: item.permission,
                          })),
                        }
                      : undefined,
                  }))}
                />
              </div>
            )}
          </div>
        </div>
      ) : null}

      {datatable.afterTitle && (
        <div className="mb-4">{datatable.afterTitle}</div>
      )}

      {/** Search and filter */}
      {datatable.hasSearch !== false || datatable.filterForm ? (
        <section
          className={cn("flex items-center justify-between gap-4", {
            // Hide the filter bar when there are no records at all.
            // Use CSS to avoid re-rendering and losing state in the search input
            hidden: contentState === DatatableContentState.NoContent,
          })}>
          {datatable.hasSearch !== false && (
            <div className="flex-grow">
              <SearchInput onChange={setSearch} height={"md"} />
            </div>
          )}
          <DatatableFilters />
        </section>
      ) : null}

      <MobileDatatableRecordCount
        contentState={contentState}
        totalResults={totalResults}
        MobileSelectButton={MobileSelectButton}
      />
    </>
  );
};
