import { ReactNode } from "react";

import { useGenericComponents } from "../../../GenericComponentsProvider";

export const StatCardGroup = ({ cards }: { cards: ReactNode[] }) => {
  const { View } = useGenericComponents();
  return (
    <View className="flex flex-row space-x-4 md:space-x-8">
      {cards.map((card, i) => {
        return (
          <View key={i} className="flex-1 flex">
            {card}
          </View>
        );
      })}
    </View>
  );
};
