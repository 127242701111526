import { TaxRateDto } from "@justraviga/classmanager-sdk";

import { bankersRounding } from "./portals/common/bankers-rounding/bankersRounding";

export function taxRateLabel(taxRate: TaxRateDto | undefined) {
  if (!taxRate) {
    return "-";
  }

  return `${taxRate.label} (${taxRate.rate}%)`;
}

export function calculateTax(
  amount: number,
  taxRate: TaxRateDto | undefined,
  taxMode: "exclusive" | "inclusive",
  hasTax: boolean,
): number {
  if (!hasTax || !taxRate) {
    return 0;
  }

  if (taxMode === "exclusive") {
    /**
     * Easy mode: Exclusive tax
     */
    return bankersRounding(amount * (taxRate.rate / 100));
  } else {
    /**
     * Harder mode: Inclusive tax
     */
    return amount - calculateNetPrice(amount, taxRate, taxMode, hasTax);
  }
}

export function calculateNetPrice(
  amount: number,
  taxRate: TaxRateDto | undefined,
  taxMode: "exclusive" | "inclusive",
  hasTax: boolean,
) {
  if (!hasTax || !taxRate) {
    return amount;
  }

  if (taxMode === "exclusive") {
    return amount;
  }

  return bankersRounding(amount / (1 + taxRate.rate / 100));
}
