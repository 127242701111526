import { Fragment } from "react";

import { CustomTransactionItem } from "@shared/components/formDefinitions/customTransactionItemForm";
import { useSettings } from "@shared/components/useSettings";
import { formatMoneyFromInteger } from "@shared/intlFormatter";
import { taxRateLabel } from "@shared/taxUtils";

export const TransactionsCreatePageItemsSummary = ({
  items,
}: {
  items: CustomTransactionItem[];
}) => {
  const { taxMode } = useSettings("tax");

  const subTotal = items.reduce((acc, detail) => {
    if (taxMode === "exclusive") {
      return acc + detail.price;
    } else {
      // tax inclusive
      return acc + detail.price - detail.taxAmount;
    }
  }, 0);

  const discountTotal = items.reduce((acc, detail) => acc + detail.discount, 0);

  //Grouped tax totals, grouped by tax rate ID
  const taxTotals = items.reduce((acc: { [key: string]: number }, item) => {
    if (item.taxRate) {
      const label = taxRateLabel(item.taxRate);
      acc[label] = (acc[label] || 0) + item.taxAmount;
    }
    return acc;
  }, {});

  const total = items.reduce((acc, detail) => acc + detail.total, 0);

  return (
    <div className={"flex flex-grow flex-col md:items-end"}>
      <div className={"grid grid-cols-2 gap-x-32 gap-y-2"}>
        <div className={"text-body-400 text-grey-600 md:text-end"}>
          Subtotal
        </div>
        <div className={"text-end text-body-400 text-grey-600"}>
          {formatMoneyFromInteger(subTotal)}
        </div>

        <div className={"text-body-400 text-grey-600  md:text-end"}>
          Discounts
        </div>
        <div className={"text-end text-body-400 text-grey-600"}>
          {discountTotal ? `-${formatMoneyFromInteger(discountTotal)}` : "-"}
        </div>

        {Object.entries(taxTotals).map(([label, taxTotal]) => (
          <Fragment key={`summary-tax-label-${label}`}>
            <div className={"text-body-400 text-grey-600  md:text-end"}>
              {label}
            </div>
            <div className={"text-end text-body-400 text-grey-600"}>
              {formatMoneyFromInteger(taxTotal as number)}
            </div>
          </Fragment>
        ))}

        <div className={"text-heading5-600  md:text-end"}>Total</div>
        <div className={"text-end text-heading5-600"}>
          {formatMoneyFromInteger(total)}
        </div>
      </div>
    </div>
  );
};
