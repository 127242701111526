import { useRef } from "react";

import { ListFeatureFlagResourceDataInner } from "@justraviga/classmanager-sdk";

import { useGenericComponents } from "@shared/components/GenericComponentsProvider";

import { getPlatformFunctions } from "shared/lib";

import {
  AdminContent,
  AdminLayout,
  AdminTitleBar,
  AdminTitleBarTitle,
} from "@/modules/admin/AdminLayout";
import { Switch } from "@/modules/common/form/Switch";

function useListAllFeatureFlags() {
  const { useApi } = getPlatformFunctions();

  const { data } = useApi("listAllFeatureFlags", {});

  // It's fine to just return an empty list while we load
  return data?.data ?? [];
}

export const FeatureFlagPage = () => {
  const { BaseCard } = useGenericComponents();

  const featureFlags = useListAllFeatureFlags();

  return (
    <AdminLayout>
      <AdminTitleBar>
        <AdminTitleBarTitle title={"Feature Flags"} />
      </AdminTitleBar>
      <AdminContent>
        <BaseCard
          bodySlot={featureFlags.map(featureFlag => (
            <FeatureFlagTableRow
              featureFlag={featureFlag}
              key={featureFlag.id}
            />
          ))}
        />
      </AdminContent>
    </AdminLayout>
  );
};

const FeatureFlagTableRow = ({
  featureFlag,
}: {
  featureFlag: ListFeatureFlagResourceDataInner;
}) => {
  const { Text, View } = useGenericComponents();
  const { api } = getPlatformFunctions();

  // Used to control the switch's disabled state while we make a request
  const switchRef = useRef<HTMLButtonElement>(null);

  async function handleClick(checked: boolean) {
    switchRef.current!.disabled = true;

    if (checked) {
      await api.featureFlags.enableGlobally({
        featureFlagId: featureFlag.id,
      });
    } else {
      await api.featureFlags.disableGlobally({
        featureFlagId: featureFlag.id,
      });
    }

    switchRef.current!.disabled = false;
  }

  return (
    <View className={"flex flex-row items-center justify-between"}>
      <Text>{featureFlag.name}</Text>
      <View>
        <Switch
          onChange={handleClick}
          checked={featureFlag.globallyEnabled}
          ref={switchRef}
        />
      </View>
    </View>
  );
};
