import { cn } from "../../../../cssUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface Props {
  isLoading: boolean;
  title: string;
  bigNumber: number;
  trend?: {
    direction: "up" | "down";
    description: string;
  };
}

export const StatCard = ({ isLoading, title, bigNumber, trend }: Props) => {
  const { View, Text, Icon } = useGenericComponents();

  return (
    <View className="flex-grow border border-grey-300 rounded p-2 md:p-4">
      <View className="relative flex flex-col space-y-1">
        <View className="flex-1">
          <Text className="text-xs md:text-sm text-grey-600">{title}</Text>
        </View>
        <View className="flex-initial">
          <Text
            className={cn(
              "mt-1 md:mt-2 text-lg md:text-2xl leading-none font-semibold text-grey-900",
              { invisible: isLoading },
            )}>
            {bigNumber}
          </Text>

          {trend && (
            <View
              className={cn(
                "hidden mt-4 md:flex flex-row items-center gap-x-1 leading-none",
                {
                  invisible: isLoading,
                },
              )}>
              <Icon
                name={
                  trend.direction === "up"
                    ? "arrowUpCircleOutline"
                    : "arrowDownCircleOutline"
                }
              />
              <Text className="text-grey-900 text-md">{trend.description}</Text>
            </View>
          )}
        </View>
        {/* We have to make sure that the loading state takes up the same space as the loaded state */}
        <View
          className={cn("absolute bottom-0 left-0", {
            hidden: !isLoading,
          })}>
          <Text className="text-grey-900">Loading...</Text>
        </View>
      </View>
    </View>
  );
};
