import { match } from "ts-pattern";

import { AdminAccountPage } from "@/modules/admin/AdminAccountPage";
import { AdminHome } from "@/modules/admin/AdminHome";
import { ActionBannerPage } from "@/modules/admin/docs/ActionBannerPage";
import { ActionMenuPage } from "@/modules/admin/docs/ActionMenuPage";
import { AlertDialogPage } from "@/modules/admin/docs/AlertDialogPage";
import { AlertPage } from "@/modules/admin/docs/AlertPage";
import { AvatarPage } from "@/modules/admin/docs/AvatarPage";
import { BannerPage } from "@/modules/admin/docs/BannerPage";
import { BigRadioPage } from "@/modules/admin/docs/BigRadioPage";
import { BreadcrumbPage } from "@/modules/admin/docs/BreadcrumbPage";
import { ButtonPage } from "@/modules/admin/docs/ButtonPage";
import { CardContainerPage } from "@/modules/admin/docs/CardContainerPage";
import { CardPage } from "@/modules/admin/docs/CardPage";
import { CheckboxPage } from "@/modules/admin/docs/CheckboxPage";
import { ChipPage } from "@/modules/admin/docs/ChipPage";
import { CollapsibleContainerPage } from "@/modules/admin/docs/CollapsibleContainerPage";
import { ColorPalettePage } from "@/modules/admin/docs/ColorPalettePage";
import { ColorPickerPage } from "@/modules/admin/docs/ColorPickerPage";
import { ContentPlaceholderPage } from "@/modules/admin/docs/ContentPlaceholderPage";
import { DatatablePage } from "@/modules/admin/docs/DatatablePage";
import { DayMonthPickerPage } from "@/modules/admin/docs/DayMonthPickerPage";
import { DecimalInputPage } from "@/modules/admin/docs/DecimalInputPage";
import { DocsPage } from "@/modules/admin/docs/DocsPage";
import { FormBuilderPage } from "@/modules/admin/docs/FormBuilderPage";
import { HorizontalSeparatorPage } from "@/modules/admin/docs/HorizontalSeparatorPage";
import { IconButtonPage } from "@/modules/admin/docs/IconButtonPage";
import { IntegerInputPage } from "@/modules/admin/docs/IntegerInputPage";
import { ListPage } from "@/modules/admin/docs/ListPage";
import { MultiSelectPage } from "@/modules/admin/docs/MultiSelectPage";
import { PasswordInputPage } from "@/modules/admin/docs/PasswordInputPage";
import { QuickActionButtonPage } from "@/modules/admin/docs/QuickActionButtonPage";
import { RangeDatePickerPage } from "@/modules/admin/docs/RangeDatePickerPage";
import { SearchInputPage } from "@/modules/admin/docs/SearchInputPage";
import { SelectPage } from "@/modules/admin/docs/SelectPage";
import { SheetPage } from "@/modules/admin/docs/SheetPage";
import { SingleDatePickerPage } from "@/modules/admin/docs/SingleDatePickerPage";
import { SwitchPage } from "@/modules/admin/docs/SwitchPage";
import { TabsPage } from "@/modules/admin/docs/TabsPage";
import { TextInputPage } from "@/modules/admin/docs/TextInputPage";
import { TimePickerPage } from "@/modules/admin/docs/TimePickerPage";
import { ToastPage } from "@/modules/admin/docs/ToastPage";
import { TooltipPage } from "@/modules/admin/docs/TooltipPage";
import { VerticalSeparatorPage } from "@/modules/admin/docs/VerticalSeparatorPage";
import { FeatureFlagPage } from "@/portals/admin/feature-flags/FeatureFlagPage";
import { RouteNotFound } from "@/routing/RouteNotFound";
import { Router } from "@/routing/router";

export const AdminRouter = () => {
  const route = Router.useRoute([
    "ActionBannerDoc",
    "ActionMenuDoc",
    "AdminAccount",
    "AdminFeatureFlags",
    "AdminHome",
    "AlertDialogDoc",
    "AlertDoc",
    "AvatarDoc",
    "BannerDoc",
    "BigRadioDoc",
    "BreadcrumbsDoc",
    "ButtonDoc",
    "QuickActionButtonDoc",
    "CardContainerDoc",
    "CardDoc",
    "CheckboxDoc",
    "ChipDoc",
    "CollapsibleContainerDoc",
    "ColorPaletteDoc",
    "ColorPickerDoc",
    "ContentPlaceholderDoc",
    "DatatableDoc",
    "DayMonthPickerDoc",
    "DecimalInputDoc",
    "DocsHome",
    "FormBuilderDoc",
    "HorizontalSeparatorDoc",
    "IconButtonDoc",
    "IntegerInputDoc",
    "ListDoc",
    "MultiSelectDoc",
    "PasswordInputDoc",
    "RangeDatePickerDoc",
    "SearchInputDoc",
    "SelectDoc",
    "SheetDoc",
    "SingleDatePickerDoc",
    "SwitchDoc",
    "TabsDoc",
    "TextInputDoc",
    "TimePickerDoc",
    "ToastDoc",
    "ToolTipDoc",
    "VerticalSeparatorDoc",
  ]);

  return match(route)
    .with({ name: "AdminHome" }, () => <AdminHome />)
    .with({ name: "AdminAccount" }, () => <AdminAccountPage />)
    .with({ name: "AdminFeatureFlags" }, () => <FeatureFlagPage />)

    .with({ name: "ActionBannerDoc" }, () => <ActionBannerPage />)
    .with({ name: "ActionMenuDoc" }, () => <ActionMenuPage />)
    .with({ name: "AlertDialogDoc" }, () => <AlertDialogPage />)
    .with({ name: "AlertDoc" }, () => <AlertPage />)
    .with({ name: "AvatarDoc" }, () => <AvatarPage />)
    .with({ name: "BannerDoc" }, () => <BannerPage />)
    .with({ name: "BigRadioDoc" }, () => <BigRadioPage />)
    .with({ name: "BreadcrumbsDoc" }, () => <BreadcrumbPage />)
    .with({ name: "ButtonDoc" }, () => <ButtonPage />)
    .with({ name: "QuickActionButtonDoc" }, () => <QuickActionButtonPage />)
    .with({ name: "CardContainerDoc" }, () => <CardContainerPage />)
    .with({ name: "CardDoc" }, () => <CardPage />)
    .with({ name: "CheckboxDoc" }, () => <CheckboxPage />)
    .with({ name: "ChipDoc" }, () => <ChipPage />)
    .with({ name: "CollapsibleContainerDoc" }, () => (
      <CollapsibleContainerPage />
    ))
    .with({ name: "ColorPickerDoc" }, () => <ColorPickerPage />)
    .with({ name: "ColorPaletteDoc" }, () => <ColorPalettePage />)
    .with({ name: "ContentPlaceholderDoc" }, () => <ContentPlaceholderPage />)
    .with({ name: "DatatableDoc" }, () => <DatatablePage />)
    .with({ name: "DayMonthPickerDoc" }, () => <DayMonthPickerPage />)
    .with({ name: "DecimalInputDoc" }, () => <DecimalInputPage />)
    .with({ name: "DocsHome" }, () => <DocsPage />)
    .with({ name: "FormBuilderDoc" }, () => <FormBuilderPage />)
    .with({ name: "HorizontalSeparatorDoc" }, () => <HorizontalSeparatorPage />)
    .with({ name: "IconButtonDoc" }, () => <IconButtonPage />)
    .with({ name: "IntegerInputDoc" }, () => <IntegerInputPage />)
    .with({ name: "ListDoc" }, () => <ListPage />)
    .with({ name: "MultiSelectDoc" }, () => <MultiSelectPage />)
    .with({ name: "PasswordInputDoc" }, () => <PasswordInputPage />)
    .with({ name: "RangeDatePickerDoc" }, () => <RangeDatePickerPage />)
    .with({ name: "SearchInputDoc" }, () => <SearchInputPage />)
    .with({ name: "SelectDoc" }, () => <SelectPage />)
    .with({ name: "SheetDoc" }, () => <SheetPage />)
    .with({ name: "SingleDatePickerDoc" }, () => <SingleDatePickerPage />)
    .with({ name: "SwitchDoc" }, () => <SwitchPage />)
    .with({ name: "TabsDoc" }, () => <TabsPage />)
    .with({ name: "TextInputDoc" }, () => <TextInputPage />)
    .with({ name: "TimePickerDoc" }, () => <TimePickerPage />)
    .with({ name: "ToastDoc" }, () => <ToastPage />)
    .with({ name: "ToolTipDoc" }, () => <TooltipPage />)
    .with({ name: "VerticalSeparatorDoc" }, () => <VerticalSeparatorPage />)
    .otherwise(() => <RouteNotFound redirectTo="AdminHome" />);
};
