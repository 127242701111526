import { StatCardGroup } from "@shared/components/modules/common/stats/StatCardGroup";
import { BillingHistoryWidget } from "@shared/components/modules/company/dashboard/BillingHistoryWidget";
import { DashboardPageHeader } from "@shared/components/modules/company/dashboard/DashboardPageHeader";
import { EnrolledStudentsWidget } from "@shared/components/modules/company/dashboard/EnrolledStudentsWidget";
import { EnrolmentTrendsWidget } from "@shared/components/modules/company/dashboard/EnrolmentTrendsWidget";
import { LinksWidget } from "@shared/components/modules/company/dashboard/LinksWidget";
import { RecentActivityWidget } from "@shared/components/modules/company/dashboard/RecentActivityWidget";
import { StripeBanner } from "@shared/components/modules/company/dashboard/StripeBanner";
import { TotalFamiliesWidget } from "@shared/components/modules/company/dashboard/TotalFamiliesWidget";
import { TotalStudentsWidget } from "@shared/components/modules/company/dashboard/TotalStudentsWidget";
import { UpcomingBirthdaysWidget } from "@shared/components/modules/company/dashboard/UpcomingBirthdaysWidget";

import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { BillingHistoryChart } from "@/modules/company/dashboard/charts/BillingHistoryChart";
import { EnrolmentTrendChart } from "@/modules/company/dashboard/charts/EnrolmentTrendChart";
import { TodaysScheduleWidget } from "@/modules/company/dashboard/TodaysScheduleWidget";
import { Router } from "@/routing/router";

import { Banner } from "../common/ui/Banner";

function goToStudent(studentId: string): void {
  Router.push("StudentDetails", { id: studentId });
}

function viewAllActivities() {
  Router.push("ActivityLogList");
}

export const CompanyDashboardPage = () => {
  return (
    <CompanyLayout>
      <CompanyContent>
        <DashboardPageHeader />
        <StripeBanner
          Banner={props => (
            <div className="pb-4 md:pb-0">
              <Banner {...props} />
            </div>
          )}
        />
        <div className="my-4">
          <StatCardGroup
            cards={[
              <TotalStudentsWidget />,
              <EnrolledStudentsWidget />,
              <TotalFamiliesWidget />,
            ]}
          />
        </div>
        <div className="grid grid-cols-1 gap-y-4 md:grid-cols-3 md:gap-x-8 md:pt-4">
          <div className="col-span-2 space-y-4 md:space-y-8">
            <TodaysScheduleWidget />

            <BillingHistoryWidget BillingHistoryChart={BillingHistoryChart} />
            <EnrolmentTrendsWidget EnrolmentTrendChart={EnrolmentTrendChart} />
          </div>
          <div className="col-span-1 space-y-4 md:space-y-8">
            <LinksWidget />
            <RecentActivityWidget
              goToStudent={goToStudent}
              viewAllActivities={viewAllActivities}
            />
            <UpcomingBirthdaysWidget />
          </div>
        </div>
      </CompanyContent>
    </CompanyLayout>
  );
};
