import { useFamilyDatatable } from "@shared/components/datatables/useFamilyDatatable";

import { AvatarListCell } from "@/modules/common/datatable/cell/AvatarListCell";
import { Datatable } from "@/modules/common/datatable/Datatable";
import { InvitationStatusIcon } from "@/modules/company/common/invitation/InvitationStatusIcon";
import { CompanyContent, CompanyLayout } from "@/modules/company/CompanyLayout";
import { useFamilyActions } from "@/modules/company/members/family/useFamilyActions";
import { withCompanyLayoutPermissionCheck } from "@/modules/company/withCompanyLayoutPermissionCheck";
import { Router } from "@/routing/router";

const FamilyListPage = () => {
  const familyActions = useFamilyActions();

  const { datatable } = useFamilyDatatable({
    Datatable,
    archive: familyActions.archive,
    archiveMany: familyActions.archiveMany,
    avatarListCell: students => <AvatarListCell list={students} />,
    deleteMany: familyActions.deleteMany,
    deleteOne: familyActions.deleteOne,
    invitationStatus: item => (
      <InvitationStatusIcon status={item.invitationStatus} />
    ),
    onRowClick: item => Router.push("FamilyDetails", { id: item.id }),
    showCreateForm: familyActions.showCreateForm,
    showInviteForm: familyActions.showInviteForm,
    showUpdateForm: family => {
      return familyActions.showUpdateForm(family, {
        primaryContact: family.primaryContact,
      });
    },
    unarchive: familyActions.unarchive,
  });
  return (
    <CompanyLayout datatablePage>
      <CompanyContent>{datatable}</CompanyContent>
    </CompanyLayout>
  );
};

const PermissionChecked = withCompanyLayoutPermissionCheck(
  "members:view",
  FamilyListPage,
  "Families",
);

export { PermissionChecked as FamilyListPage };
