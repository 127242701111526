import { ComponentType } from "react";

import { DatatableContentState, useDatatable } from "../../datatable";
import { useGenericComponents } from "../GenericComponentsProvider";

export const MobileDatatableRecordCount = ({
  totalResults,
  contentState,
  MobileSelectButton,
}: {
  totalResults: number;
  contentState: DatatableContentState;
  MobileSelectButton: ComponentType<{
    contentState: DatatableContentState;
    totalResults: number;
  }>;
}) => {
  const { View, Text, IconButton } = useGenericComponents();
  const { datatable, selectMode, modelSelection, query } = useDatatable();

  // Only show this row if we have results
  const relevantStates = [
    DatatableContentState.Loaded,
    DatatableContentState.NoResults,
  ];
  if (!relevantStates.includes(contentState)) {
    return null;
  }

  // Only show this row if there are some actions to show
  if (
    datatable.showTotalRecords === false &&
    !datatable.groupActions &&
    !datatable.exportAction
  ) {
    return null;
  }

  return (
    <View className={"flex flex-row items-center justify-between py-2"}>
      {datatable.showTotalRecords !== false && (
        <View>
          <Text className={"text-grey-600 text-body-400"}>
            {selectMode
              ? `${modelSelection.count()} selected`
              : String(totalResults)
                  .concat(" record")
                  .concat(totalResults === 1 ? "" : "s")}
          </Text>
        </View>
      )}

      <View className="flex flex-row items-center justify-end space-x-2">
        {datatable.exportAction && (
          <View>
            <IconButton
              icon="downloadOutline"
              border="rounded"
              variant="secondary-fill"
              onClick={() => datatable.exportAction?.(query.toRequest())}
              size="sm"
            />
          </View>
        )}
        {datatable?.groupActions && (
          <View>
            <MobileSelectButton
              totalResults={totalResults}
              contentState={contentState}
            />
          </View>
        )}
      </View>
    </View>
  );
};
