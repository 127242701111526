import { createContext, ReactNode, useContext } from "react";

import { BannerAction, BannerIcon, BannerVariant } from "./bannerTypes";

// All properties are optional because Alerts are always included on the page
export interface AlertProps {
  content?: string | ReactNode;
  variant?: BannerVariant;
  action?: BannerAction;
  dismissible?: boolean;
  icon?: BannerIcon;
  timeout?: number | null; // null means no timeout, and the alert must be dismissed manually
  show?: boolean;
}

export type ShowAlertProps = Omit<AlertProps, "show">;

export type AlertState = {
  alertProps?: ShowAlertProps;
  show: boolean;
};

type AlertSubscriber = (state: AlertState) => void;

// Timeout to automatically dismiss the Alert
let alertDismissTimeout: NodeJS.Timeout | undefined;

// Timeout to remove the Alert from the DOM
let alertHideTimeout: NodeJS.Timeout | undefined;

const subscribers: Record<string, AlertSubscriber> = {};

export const subscribeToAlertStateChanges = (
  key: string,
  subscriber: AlertSubscriber,
) => {
  subscribers[key] = subscriber;
};

export const unsubscribeFromAlertStateChanges = (key: string) => {
  delete subscribers[key];
};

export const alertState: AlertState = {
  alertProps: undefined,
  show: false,
};

export type ShowAlertFn = (options: ShowAlertProps) => void;
export type HideAlertFn = (key?: string) => void;

export type AlertContextType = {
  showAlert: ShowAlertFn;
  hideAlert: HideAlertFn;
};

export const AlertContext = createContext<AlertContextType>(
  undefined as unknown as AlertContextType,
);

const updateAlertState = (newState: Partial<AlertState>) => {
  Object.assign(alertState, newState);
  Object.values(subscribers).forEach(subscriber => subscriber(alertState));
};

export const showAlert: ShowAlertFn = (options: AlertProps) => {
  // make sure we don't hide the Alert too soon if a previous Alert was showing
  clearTimeout(alertDismissTimeout);
  clearTimeout(alertHideTimeout);

  // Set up the Alert ready to show
  updateAlertState({
    alertProps: options,
    show: true,
  });

  // Configure ourselves to hide the Alert.  Defaults to 2s
  alertDismissTimeout =
    options.timeout === null
      ? undefined
      : setTimeout(hideAlert, options.timeout ?? 2000);
};

export const hideAlert: HideAlertFn = () => {
  updateAlertState({
    show: false,
  });
  alertHideTimeout = setTimeout(() => {
    // These bits will remove it from the DOM and clear it up when we're ready
    updateAlertState({
      alertProps: undefined,
    });
  }, 500);
};

export const useAlert = () => {
  const context = useContext<AlertContextType>(AlertContext);

  if (context === undefined) {
    throw new Error("Alert used outside of provider");
  }

  return context;
};
