import { AlertProps, colors, showAlert } from "shared/lib";

import {
  AdminContent,
  AdminLayout,
  AdminTitleBar,
  AdminTitleBarTitle,
} from "@/modules/admin/AdminLayout";
import { DocSection } from "@/modules/admin/docs/components/docSection/DocSection";
import { Button } from "@/modules/common/ui/button/Button";

const alerts: { [key: string]: AlertProps } = {
  default: {
    variant: "default",
    content: "Something happened",
  },
  success: {
    variant: "success",
    content: "Something succeeded",
  },
  error: {
    variant: "error",
    content: "An error occurred",
  },
  info: {
    variant: "info",
    content: "Just letting you know...",
  },
  warning: {
    variant: "warning",
    content: "Watch out, something happened",
  },
  critical: {
    variant: "critical",
    content: "CRITICAL ERROR",
  },
  important: {
    variant: "important",
    content: "Something for your attention",
  },
  secondaryContent: {
    content: "Primary content",
    action: {
      text: "Action",
      onClick() {
        alert("Clicked!");
      },
    },
  },
  noCloseButton: {
    content: "Can't close this",
    dismissible: false,
  },
  timeout: {
    content: "This will hide quickly",
    timeout: 500,
  },
  lotsOfText: {
    content:
      "This is an alert with a lot of text explaining something very important that has happened. " +
      "It should flow over several lines and make for a really tall alert.  It has so much text " +
      "it could event take up 5 lines of output!  We need to make sure this is accounted for in " +
      "the top offset of the alert so it hides itself properly as we cannot always know the height " +
      "of the component in advance to set its top offset",
  },
  customIcon: {
    content: "I have a custom icon",
    icon: {
      name: "trashOutline",
      color: colors.purple[500],
    },
  },
} as const;

export const AlertPage = () => {
  return (
    <AdminLayout>
      <AdminTitleBar>
        <AdminTitleBarTitle title="Alert" />
      </AdminTitleBar>
      <AdminContent>
        <DocSection>
          <DocSection.title title={"Default types"} />
          <div className={"flex flex-wrap justify-between"}>
            <Button
              onClick={() => showAlert(alerts.default)}
              text={"Default"}
              variant={"secondary"}
            />
            <Button
              onClick={() => showAlert(alerts.success)}
              text={"Success"}
              variant={"secondary"}
            />
            <Button
              onClick={() => showAlert(alerts.error)}
              text={"Error"}
              variant={"secondary"}
            />
            <Button
              onClick={() => showAlert(alerts.info)}
              text={"Info"}
              variant={"secondary"}
            />
            <Button
              onClick={() => showAlert(alerts.warning)}
              text={"Warning"}
              variant={"secondary"}
            />
          </div>
        </DocSection>
        <DocSection>
          <DocSection.title title={"Other types"} />
          <div className={"flex flex-wrap justify-between"}>
            <Button
              onClick={() => showAlert(alerts.critical)}
              text={"Critical"}
              variant={"secondary"}
            />
            <Button
              onClick={() => showAlert(alerts.important)}
              text={"Important"}
              variant={"secondary"}
            />
          </div>
        </DocSection>
        <DocSection>
          <DocSection.title title={"Other options"} />
          <div className={"flex flex-wrap justify-between"}>
            <Button
              onClick={() => showAlert(alerts.secondaryContent)}
              text={"With secondary content"}
              variant={"secondary"}
            />
            <Button
              onClick={() => showAlert(alerts.noCloseButton)}
              text={"No close button"}
              variant={"secondary"}
            />
            <Button
              onClick={() => showAlert(alerts.timeout)}
              text={"Shorter timeout"}
              variant={"secondary"}
            />
            <Button
              onClick={() => showAlert(alerts.lotsOfText)}
              text={"Lots of text"}
              variant={"secondary"}
            />
            <Button
              onClick={() => showAlert(alerts.customIcon)}
              text={"Custom icon"}
              variant={"secondary"}
            />
          </div>
        </DocSection>
      </AdminContent>
    </AdminLayout>
  );
};
