import { createContext } from "react";

export interface FeatureContext {
  featureGloballyEnabled(featureName: string): boolean;
  featureEnabled(featureName: string): boolean;
}

export const FeatureFlagContext = createContext(
  undefined as unknown as FeatureContext,
);
