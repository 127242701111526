import {
  PricingModel,
  QuoteDto,
  TransactionDetailType,
} from "@justraviga/classmanager-sdk";

import { CheckoutItem } from "./CheckoutItem";
import { CheckoutSeasonAdditionalFees } from "./CheckoutSeasonAdditionalFees";
import { CheckoutSeasonItems } from "./CheckoutSeasonItems";
import { CheckoutSection } from "./CheckoutSection";
import { PricingPlanSelector } from "./PricingPlanSelector";
import { seasonTuitionFeesSubTotal } from "../../../../checkoutUtils";
import { formatMoneyFromInteger } from "../../../../intlFormatter";
import { CollapsibleContainer } from "../../../collapsibleContainer/CollapsibleContainer";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { StyledText } from "../../../text/StyledText";
import { useSettings } from "../../../useSettings";

export const CheckoutSeason = ({
  seasonQuote,
  pricingPlanId,
  setPricingPlanId,
}: {
  seasonQuote: QuoteDto;
  pricingPlanId: string | undefined;
  setPricingPlanId: (id: string, seasonId: string) => void;
}) => {
  const { View } = useGenericComponents();
  const { taxMode } = useSettings("tax");

  function getPricingModelText(pricingModel: PricingModel): string {
    switch (pricingModel) {
      case "per-season":
        return t("entity.season", { count: 1 });
      case "per-month":
        return seasonQuote.enabledPricingPlans.length === 1 &&
          seasonQuote.enabledPricingPlans[0].type === "one-off"
          ? t("entity.season", { count: 1 })
          : "month";
      case "per-lesson":
        return "lesson";
      default:
        throw new Error("Invalid pricing model");
    }
  }

  const additionalFees = seasonQuote.items.filter(
    item => item.type === TransactionDetailType.AdditionalFee,
  );
  const tuitionItems = seasonQuote.items.filter(
    item => item.type === TransactionDetailType.Tuition,
  );

  return (
    <CheckoutSection
      heading={`Tuition fees (${seasonQuote.season.name})`}
      headingSuffix={
        <View className={"flex flex-row items-center justify-between"}>
          <View>
            <StyledText
              style={"heading"}
              text={formatMoneyFromInteger(
                seasonTuitionFeesSubTotal(seasonQuote, taxMode),
              )}
            />
          </View>
          <View>
            <StyledText
              style={"subheading"}
              text={`/${getPricingModelText(seasonQuote.season.pricingModel)}`}
            />
          </View>
        </View>
      }>
      <CollapsibleContainer
        collapseText={"Less details"}
        expandText={"More details"}>
        <View>
          <CheckoutSection spacing={"tight"}>
            <View>
              <CheckoutSeasonItems items={tuitionItems} />
            </View>
            <View>
              <CheckoutItem
                text={"Discount"}
                style={"light"}
                amount={seasonQuote.discountTotal}
              />
            </View>
          </CheckoutSection>
        </View>
      </CollapsibleContainer>
      {seasonQuote.enabledPricingPlans.length > 1 && (
        <View>
          <PricingPlanSelector
            pricingPlans={seasonQuote.enabledPricingPlans}
            selectedPricingPlanId={pricingPlanId}
            setPricingPlanId={setPricingPlanId}
            seasonQuote={seasonQuote}
          />
        </View>
      )}
      {additionalFees.length > 0 && (
        <View>
          <CheckoutSeasonAdditionalFees
            additionalFees={additionalFees}
            seasonName={seasonQuote.season.name}
          />
        </View>
      )}
    </CheckoutSection>
  );
};
