import {
  AggregateTransactionDto,
  CheckoutOperationRequest,
  CheckoutRequestItems,
} from "@justraviga/classmanager-sdk";

import { ConfirmBookingButton } from "./CheckoutButtons/ConfirmBookingButton";
import { ContinueToPayButton } from "./CheckoutButtons/ContinueToPayButton";
import { CheckoutLayout } from "./CheckoutLayout";
import { CheckoutLeftColumn } from "./CheckoutLeftColumn";
import { CheckoutRightColumn } from "./CheckoutRightColumn";
import { CheckoutSeasons } from "./CheckoutSeasons";
import { CheckoutSection } from "./CheckoutSection";
import { CheckoutTrials } from "./CheckoutTrials";
import { PaymentSummary } from "./PaymentSummary";
import { RegistrationFeesSummary } from "./RegistrationFeesSummary";
import { SpaceNotGuaranteedBanner } from "./SpaceNotGuaranteedBanner";
import { useCheckoutData } from "../../../../data/useCheckoutData";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { StyledText } from "../../../text/StyledText";

interface CustomerCheckoutSummaryPageProps {
  openPaymentForm: (
    familyId: string,
    balance: number,
    requestItems: CheckoutRequestItems,
  ) => Promise<void>;
  onCheckoutSuccess: (
    result?: AggregateTransactionDto[],
    checkoutOperation?: CheckoutOperationRequest,
  ) => void;
}

export const CustomerCheckoutSummaryPage = ({
  openPaymentForm,
  onCheckoutSuccess,
}: CustomerCheckoutSummaryPageProps) => {
  const {
    allPricingPlansSelected,
    setSeasonPricingPlan,
    selectedPricingPlanIds,
    quote,
    hasTuition,
    hasRegistrationFees,
    hasTrials,
    isLoadingBasket,
    total,
    additionalFees,
    registrationFees,
    tax,
    trialFees,
    tuitionFees,
    subtotalExTax,
  } = useCheckoutData();

  const { HorizontalSeparator, View } = useGenericComponents();

  if (isLoadingBasket) {
    return <StyledText>Loading basket...</StyledText>;
  }

  if (!quote) {
    return <StyledText>Basket failed to load.</StyledText>;
  }

  return (
    <CheckoutLayout>
      <CheckoutLeftColumn>
        <CheckoutSection spacing={"wide"}>
          <View>
            <StyledText text={"Transaction details"} style={"big"} />
          </View>
          {hasRegistrationFees && (
            <View>
              <RegistrationFeesSummary
                registrationFees={quote.registrationFees}
              />
            </View>
          )}
          {hasRegistrationFees && (hasTuition || hasTrials) && (
            <View>
              <HorizontalSeparator spacing={0} />
            </View>
          )}
          {hasTuition && (
            <View>
              <CheckoutSeasons
                seasons={quote.seasons}
                selectedPricingPlanIds={selectedPricingPlanIds}
                setSeasonPricingPlan={setSeasonPricingPlan}
              />
            </View>
          )}
          {hasTuition && hasTrials && (
            <View>
              <HorizontalSeparator spacing={0} />
            </View>
          )}
          {hasTrials && (
            <View>
              <CheckoutTrials seasons={quote.seasons} />
            </View>
          )}
          <View className={"w-full md:hidden pb-8"}>
            <HorizontalSeparator spacing={0} />
          </View>
        </CheckoutSection>
      </CheckoutLeftColumn>
      <CheckoutRightColumn>
        <CheckoutSection>
          <View>
            <PaymentSummary
              additionalFees={additionalFees}
              allPricingPlansSelected={allPricingPlansSelected}
              registrationFees={registrationFees}
              subtotalExTax={subtotalExTax}
              tax={tax}
              trialFees={trialFees}
              total={total}
              tuitionFees={tuitionFees}
            />
          </View>
          <View>
            <SpaceNotGuaranteedBanner />
          </View>
          <View>
            {total > 0 ? (
              <ContinueToPayButton
                allPricingPlansSelected={allPricingPlansSelected}
                total={total}
                openPaymentForm={openPaymentForm}
                quoteResponse={quote}
                selectedPricingPlanIds={selectedPricingPlanIds}
              />
            ) : (
              <ConfirmBookingButton
                onSuccess={onCheckoutSuccess}
                selectedPricingPlanIds={selectedPricingPlanIds}
                quoteResponse={quote}
                allPricingPlansSelected={allPricingPlansSelected}
              />
            )}
          </View>
        </CheckoutSection>
      </CheckoutRightColumn>
    </CheckoutLayout>
  );
};
