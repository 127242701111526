import { UpdateCompanySettingsRequest } from "@justraviga/classmanager-sdk";

import { FormDefinitionBuilder } from "../../../../../forms/formDefinitionBuilder";
import { getPlatformFunctions } from "../../../../../platformSpecific";
import {
  AutomatedTuitionSettingsForm,
  AutomatedTuitionSettingsFormProps,
} from "../../../../formDefinitions/automatedTuitionSettingsForm";
import {
  seasonDiscountFormDefinition,
  seasonDiscountFormSubmit,
} from "../../../../formDefinitions/seasonDiscountForm";
import { useGenericComponents } from "../../../../GenericComponentsProvider";

interface DiscountFormProps {
  discountPercent: number | null;
  setDiscountPercent: (discount: number) => void;
}

export const useCreateSeasonSettingsSheet = () => {
  const { usePlatformEntityActions } = getPlatformFunctions();
  const { GenericForm } = useGenericComponents();

  const automatedTuitionSettings = usePlatformEntityActions<
    Pick<
      Required<UpdateCompanySettingsRequest>["billing"],
      "billingTiming" | "billingDayOfMonth"
    > & { saveDefault: boolean }
  >({ entity: "company" });

  // const installmentsSettings = usePlatformEntityActions<{
  //   installments: number;
  // }>({ entity: "company" });

  const discountSettings = usePlatformEntityActions<{
    discount: number;
  }>({ entity: "company" });

  const paymentStartDateSettings = usePlatformEntityActions<{
    paymentStartDate: string | null;
  }>({ entity: "company" });

  return {
    showAutomatedTuitionSettingsForm: ({
      userBillingDayOfMonth,
      setUserBillingDayOfMonth,
      userBillingTiming,
      setUserBillingTiming,
    }: AutomatedTuitionSettingsFormProps) => {
      return automatedTuitionSettings.showUpdateForm({
        title: "Automated tuition settings",
        form: (
          <AutomatedTuitionSettingsForm
            hideForm={automatedTuitionSettings.hideForm}
            userBillingDayOfMonth={userBillingDayOfMonth}
            setUserBillingDayOfMonth={setUserBillingDayOfMonth}
            userBillingTiming={userBillingTiming}
            setUserBillingTiming={setUserBillingTiming}
          />
        ),
      });
    },
    // showInstallmentsForm: ({
    //   numberOfInstallments,
    //   setCustomNumberOfInstallments,
    // }: {
    //   numberOfInstallments: number | null;
    //   setCustomNumberOfInstallments: (installments: number) => void;
    // }) => {
    //   return installmentsSettings.showUpdateForm({
    //     title: "Change number of installments",
    //     form: (
    //       <GenericForm
    //         apiRequest={(formData: { numberOfInstallments: number }) =>
    //           Promise.resolve(formData)
    //         }
    //         defaultValues={{
    //           numberOfInstallments,
    //         }}
    //         formDefinitionHook={() =>
    //           new FormDefinitionBuilder<{
    //             numberOfInstallments: number;
    //           }>()
    //             .integer("numberOfInstallments", {
    //               label: "Number of installments",
    //               required: true,
    //             })
    //             .getDefinition()
    //         }
    //         onSuccess={form => {
    //           setCustomNumberOfInstallments(form.numberOfInstallments);
    //           installmentsSettings.hideForm();
    //         }}
    //       />
    //     ),
    //   });
    // },
    showAddDiscountForm: ({
      discountPercent,
      setDiscountPercent,
    }: DiscountFormProps) => {
      return discountSettings.showUpdateForm({
        title: "Add discount",
        form: (
          <GenericForm
            apiRequest={seasonDiscountFormSubmit}
            defaultValues={{
              discountPercent,
            }}
            formDefinitionHook={seasonDiscountFormDefinition}
            onSuccess={form => {
              setDiscountPercent(form.discountPercent);
              discountSettings.hideForm();
            }}
          />
        ),
      });
    },
    showEditDiscountForm: ({
      discountPercent,
      setDiscountPercent,
    }: DiscountFormProps) => {
      return discountSettings.showUpdateForm({
        title: "Edit discount",
        form: (
          <GenericForm
            apiRequest={seasonDiscountFormSubmit}
            defaultValues={{
              discountPercent,
            }}
            formDefinitionHook={seasonDiscountFormDefinition}
            onSuccess={form => {
              setDiscountPercent(form.discountPercent);
              discountSettings.hideForm();
            }}
          />
        ),
      });
    },
    showPaymentStartDateForm: ({
      paymentStartDate,
      userPaymentStartDate,
      setUserPaymentStartDate,
    }: {
      paymentStartDate: string | null;
      userPaymentStartDate: string | null;
      setUserPaymentStartDate: (setUserPaymentStartDate: string | null) => void;
    }) => {
      return paymentStartDateSettings.showUpdateForm({
        title: "Edit dates",
        form: (
          <GenericForm
            apiRequest={(formData: { paymentStartDate: string | null }) =>
              Promise.resolve(formData)
            }
            defaultValues={{
              paymentStartDate: userPaymentStartDate || paymentStartDate,
            }}
            formDefinitionHook={() =>
              new FormDefinitionBuilder<{
                paymentStartDate: string | null;
              }>()
                .date("paymentStartDate", {
                  label: "Payment date",
                  required: true,
                })
                .getDefinition()
            }
            onSuccess={form => {
              setUserPaymentStartDate(form.paymentStartDate);
              paymentStartDateSettings.hideForm();
            }}
          />
        ),
      });
    },
  };
};
