import { ReportingApi, ReportUrlDto } from "@justraviga/classmanager-sdk";

import { hideAlert, showAlert } from "../alertState";
import { FileDownloadingAlert } from "../components/downloads/FileDownloadingAlert";
import { getPlatformFunctions } from "../platformSpecific";

import { DatatableRequest } from "./index";

type ReportingMethod = keyof ReportingApi;

// Extract all the Reporting API methods that return a ReportUrlDto
type ExportMethod = {
  [K in ReportingMethod]: ReportingApi[K] extends (
    request: DatatableRequest,
  ) => Promise<ReportUrlDto>
    ? K
    : never;
}[ReportingMethod];

/**
 * Create a Datatable export action that will request a data export and download it.
 */
export const useExportAction = (
  apiMethod: ExportMethod,
  additionalParams?: Partial<DatatableRequest>,
) => {
  const { api, downloadFile } = getPlatformFunctions();

  return async (query?: DatatableRequest) => {
    showAlert({
      content: <FileDownloadingAlert />,
      variant: "default",
      timeout: null,
    });

    // Remove any pagination params from the query
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { page: _, pageSize: __, ...filterParams } = query ?? {};

    const { url } = await api.reporting[apiMethod]({
      ...filterParams,
      ...additionalParams,
      selectAll: true,
    });
    downloadFile(url);
    hideAlert();
  };
};
