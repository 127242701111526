import { BillItemDto } from "@justraviga/classmanager-sdk";

import { CheckoutItem } from "./CheckoutItem";
import { CheckoutSection } from "./CheckoutSection";
import { itemSubtotalForUser } from "../../../../checkoutUtils";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { useSettings } from "../../../useSettings";

export const CheckoutSeasonItems = ({ items }: { items: BillItemDto[] }) => {
  const { View } = useGenericComponents();
  const { taxMode } = useSettings("tax");
  return (
    <CheckoutSection spacing={"tight"}>
      {items.map((tuitionItem, index) => (
        <View key={index}>
          <CheckoutSection spacing={"tight"}>
            <View>
              <CheckoutItem
                text={tuitionItem.description}
                amount={
                  itemSubtotalForUser(tuitionItem, taxMode) +
                  // We always want to show the amount *before* discount here
                  Math.abs(tuitionItem.discountTotal)
                }
              />
            </View>
          </CheckoutSection>
        </View>
      ))}
    </CheckoutSection>
  );
};
