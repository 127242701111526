import {
  PricingPlanDto,
  QuoteDto,
  QuoteResponse,
  TaxMode as TaxModeEnum,
} from "@justraviga/classmanager-sdk";

import { SelectedPricingPlanIds } from "./data/useCheckoutData";
import { monthsLeftInSeason } from "./seasonUtils";

type TaxMode = `${TaxModeEnum}`;

export const quoteHasRegistrationFees = (quote: QuoteResponse) =>
  quote.registrationFees.length > 0;

export const getRegistrationFeesSubTotalForQuote = (
  quote: QuoteResponse,
  taxMode: TaxMode,
) =>
  quote.registrationFees.reduce(
    (a, c) =>
      a +
      itemSubtotalForUser(
        // todo: Can be reverted to just passing 'c' once the backend casing is fixed
        {
          ...c,
          subtotal: c.subTotal,
        },
        taxMode,
      ),
    0,
  );

export const quoteHasTuitionFees = (quote: QuoteResponse) =>
  quote.seasons.some(season =>
    season.items.some(item => item.type === "tuition"),
  );

export const getPricingPlanForSeasonQuote = (
  seasonQuote: QuoteDto,
  selectedPricingPlanIds: SelectedPricingPlanIds,
) =>
  seasonQuote.enabledPricingPlans.find(pricingPlan => {
    return (
      // if there's only one pricing plan return that otherwise try and
      // find the one that might be saved in selectedPricingPlanIds,
      // that could be undefined
      seasonQuote.enabledPricingPlans.length === 1 ||
      pricingPlan.id === selectedPricingPlanIds[seasonQuote.season.id]
    );
  });

export const tuitionFeesSubTotal = (
  quote: QuoteResponse,
  selectedPricingPlanIds: SelectedPricingPlanIds,
  taxMode: TaxMode,
) =>
  quote.seasons.reduce((runningTotal, seasonQuote) => {
    const pricingPlan = getPricingPlanForSeasonQuote(
      seasonQuote,
      selectedPricingPlanIds,
    );

    // if there's no pricing plan found from above then the upfront cost is
    // currently 0, otherwise we need to add the season price for that pricing plan
    return (
      runningTotal +
      (pricingPlan !== undefined
        ? seasonTuitionSubTotalForPlan(seasonQuote, pricingPlan, taxMode)
        : 0)
    );
  }, 0);

export const discountMultiplierForPricingPlan = (pricingPlan: PricingPlanDto) =>
  1 - (pricingPlan.discount ?? 0) / 100;

export const seasonTuitionFeesSubTotal = (
  seasonQuote: QuoteDto,
  taxMode: TaxMode,
) =>
  seasonQuote.items
    .filter(item => item.type === "tuition")
    .map(item => itemSubtotalForUser(item, taxMode))
    .reduce((a, c) => a + c, 0);

export const seasonTuitionSubTotalForPlan = (
  seasonQuote: QuoteDto,
  pricingPlan: PricingPlanDto,
  taxMode: TaxMode,
) => {
  const tuitionFees = seasonTuitionFeesSubTotal(seasonQuote, taxMode);

  const upfrontDiscount = discountMultiplierForPricingPlan(pricingPlan);

  return pricingPlan.type === "one-off"
    ? tuitionFees *
        (seasonQuote.enabledPricingPlans.length === 1
          ? 1
          : monthsLeftInSeason(seasonQuote.season)) *
        upfrontDiscount
    : tuitionFees;
};

export const quoteHasTrials = (quote: QuoteResponse) =>
  quote.seasons.some(season =>
    season.items.some(item => item.type === "trial"),
  );

export const trialFeesSubTotal = (quote: QuoteResponse, taxMode: TaxMode) =>
  quote.seasons.reduce(
    (a, c) =>
      a +
      c.items
        .filter(item => item.type === "trial")
        .map(item => itemSubtotalForUser(item, taxMode))
        .reduce((a, c) => a + c, 0),
    0,
  );

export const additionalFeesSubTotal = (
  quote: QuoteResponse,
  taxMode: TaxMode,
) =>
  quote.seasons.reduce(
    (runningTotal, seasonQuote) =>
      runningTotal +
      seasonQuote.items
        .filter(item => item.type === "additional-fee")
        .map(item => itemSubtotalForUser(item, taxMode))
        .reduce((a, c) => a + c, 0),
    0,
  );

export const seasonTuitionTaxForPlan = (
  seasonQuote: QuoteDto,
  pricingPlan: PricingPlanDto,
) => {
  /**
   * Temp solution while we wait to move to Orders for this stuff.
   * We are calculating percentages of rounded values which is not good and can give rounding errors
   * (Rob has seen these!) so we must calculate the tax from scratch for each tuition item if we're then
   * applying a discount here to go with it
   */
  if (pricingPlan.type === "one-off") {
    // If there is only 1 enabled pricing plan, the Quote will already be a "full season" quote,
    // so no need to multiply it up
    const months =
      seasonQuote.enabledPricingPlans.length === 1
        ? 1
        : monthsLeftInSeason(seasonQuote.season);

    const upfrontDiscountMultiplier =
      discountMultiplierForPricingPlan(pricingPlan);

    // calculate tax of tuition
    return seasonQuote.items
      .filter(item => item.type === "tuition")
      .map(item => {
        // taxTotal might be null 🙄
        if (!item.taxTotal) {
          return 0;
        }

        // tax description is "Tax (12.345%)" and we just want "12.345"
        const taxRate = Number(
          item.taxDescription!.replaceAll(/[a-z ()%]/gi, ""),
        );

        const taxAmount = item.subtotal * (taxRate / 100);

        return Math.round(taxAmount * upfrontDiscountMultiplier * months);
      })
      .reduce((a, c) => a + c, 0);
  } else {
    // For other pricing plans we just want to add up the tax amounts on tuition items
    return seasonQuote.items
      .filter(item => item.type === "tuition")
      .map(item => item.taxTotal)
      .filter(num => num !== null)
      .reduce((a, c) => a + c, 0);
  }
};

export const taxTotal = (
  quote: QuoteResponse,
  selectedPricingPlanIds: SelectedPricingPlanIds,
) =>
  quote.seasons.reduce((runningTotal, seasonQuote) => {
    const pricingPlan = getPricingPlanForSeasonQuote(
      seasonQuote,
      selectedPricingPlanIds,
    );

    // if there's no pricing plan found from above then the upfront cost is
    // currently 0, otherwise we need to add the season price for that pricing plan
    const tuitionFeesTax =
      pricingPlan !== undefined
        ? seasonTuitionTaxForPlan(seasonQuote, pricingPlan)
        : 0;

    const additionalFeesTax = seasonQuote.items
      .filter(item => item.type === "additional-fee")
      .map(item => item.taxTotal)
      .filter(num => num !== null)
      .reduce((a, c) => a + c, 0);

    const trialFeesTax = seasonQuote.items
      .filter(item => item.type === "trial")
      .map(item => item.taxTotal)
      .filter(num => num !== null)
      .reduce((a, c) => a + c, 0);

    return runningTotal + tuitionFeesTax + additionalFeesTax + trialFeesTax;
  }, 0) + quote.registrationFees.reduce((a, c) => a + c.taxTotal, 0);

interface TaxableItem {
  subtotal: number;
  taxTotal: number | null;
}

export const itemSubtotalForUser = (billItem: TaxableItem, taxMode: TaxMode) =>
  billItem.subtotal + (taxMode === "inclusive" ? Number(billItem.taxTotal) : 0);
