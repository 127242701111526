import { formatMoneyFromInteger } from "@shared/intlFormatter";
import { calculateNetPrice, taxRateLabel } from "@shared/taxUtils";

import {
  CustomTransactionItem,
  useCustomTransactionAddItemDefinition,
} from "shared/components";

import { GenericForm } from "@/modules/common/form/GenericForm";
import { useSheet } from "@/modules/common/overlays/dialog/context/useSheet";
import { Button } from "@/modules/common/ui/button/Button";

export const CustomTransactionItemForm = ({
  currentItem,
  saveItem,
}: {
  currentItem?: CustomTransactionItem;
  saveItem: (item: CustomTransactionItem) => void;
}) => {
  const { closeSheet } = useSheet();

  const {
    formHook,
    validateRequest,
    defaultValues,
    item,
    editingDiscount,
    setEditingDiscount,
    editingTaxRate,
    setEditingTaxRate,
    hasTax,
    taxMode,
    defaultTax,
    success,
  } = useCustomTransactionAddItemDefinition({
    currentItem,
    close: closeSheet,
    saveItem,
  });

  return (
    <div>
      <GenericForm
        apiRequest={validateRequest}
        defaultValues={defaultValues}
        formDefinitionHook={() => formHook}
        onSuccess={success}
      />

      <section>
        {!editingDiscount && (
          <div className={"-ml-2 mb-2"}>
            <Button
              variant={"tertiaryLight"}
              size={"xs"}
              onClick={() => setEditingDiscount(true)}>
              Add discount
            </Button>
          </div>
        )}

        {hasTax && defaultTax && !editingTaxRate && (
          <div className={"flex items-start justify-between pb-8 pt-4"}>
            <div>
              <div className={"text-label-400 text-grey-600"}>Tax rate</div>

              <div className={"text-body-400 text-grey-900"}>
                {currentItem?.taxRate
                  ? `${currentItem.taxRate.label} (${formatMoneyFromInteger(currentItem.taxAmount)})`
                  : `${defaultTax.label}`}
              </div>
            </div>

            <div className={"-mr-2 -mt-2"}>
              <Button
                variant={"tertiaryLight"}
                size={"xs"}
                onClick={() => setEditingTaxRate(true)}>
                Edit
              </Button>
            </div>
          </div>
        )}
      </section>

      <section className={"space-y-2"}>
        <div className={"flex justify-between"}>
          <span className={"text-body-400 text-grey-600"}>Unit price</span>
          <span className={"text-body-400 text-grey-600"}>
            {formatMoneyFromInteger(
              calculateNetPrice(item.price, item.taxRate, taxMode, hasTax),
            )}
          </span>
        </div>

        {item.discount && item.discount > 0 ? (
          <div className={"flex justify-between"}>
            <span className={"text-body-400 text-grey-600"}>Discount</span>
            <span
              className={
                "text-body-400 text-grey-600"
              }>{`-${formatMoneyFromInteger(item.discount)}`}</span>
          </div>
        ) : null}

        {item.taxRate && (
          <div className={"flex justify-between"}>
            <span className={"text-body-400 text-grey-600"}>
              {taxRateLabel(item.taxRate)}
            </span>
            <span className={"text-body-400 text-grey-600"}>
              {formatMoneyFromInteger(item.taxAmount)}
            </span>
          </div>
        )}

        <div className={"flex justify-between"}>
          <span className={"text-body-400 text-grey-900"}>Amount</span>
          <span className={"text-body-400 text-grey-900"}>
            {formatMoneyFromInteger(item.total)}
          </span>
        </div>
      </section>
    </div>
  );
};
