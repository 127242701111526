import { useEffect, useState } from "react";

import { QuoteResponse } from "@justraviga/classmanager-sdk";

import { showAlert } from "../alertState";
import { useAuthState } from "../auth/useAuthState";
import { getQuoteFn } from "../basket/basketUtils";
import {
  additionalFeesSubTotal,
  getRegistrationFeesSubTotalForQuote,
  quoteHasRegistrationFees,
  quoteHasTrials,
  quoteHasTuitionFees,
  taxTotal,
  trialFeesSubTotal,
  tuitionFeesSubTotal,
} from "../checkoutUtils";
import { useBasket } from "../components/basket/useBasket";
import { useSettings } from "../components/useSettings";
import { getPlatformFunctions } from "../platformSpecific";

type PricingPlan = string;
type SeasonId = string;
export type SelectedPricingPlanIds = Record<SeasonId, PricingPlan>;

export const useCheckoutData = () => {
  const { api } = getPlatformFunctions();
  const { account } = useAuthState();
  const { taxMode } = useSettings("tax");

  const [isLoadingBasket, setIsLoadingBasket] = useState(true);
  const [quote, setQuote] = useState<QuoteResponse | null>(null);
  const [selectedPricingPlanIds, setSelectedPricingPlanIds] =
    useState<SelectedPricingPlanIds>({});

  const { getQuote } = useBasket();

  useEffect(() => {
    getQuote(getQuoteFn(api, account!.entityId!))
      .then(q => {
        setQuote(q);
        setIsLoadingBasket(false);
        return q;
      })
      .catch(e => {
        setIsLoadingBasket(false);
        e.messages?.length > 0 &&
          showAlert({ variant: "error", content: e.messages[0] });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const setSeasonPricingPlan = (pricingPlanId: string, seasonId: string) => {
    setSelectedPricingPlanIds(orig => ({
      ...orig,
      [seasonId]: pricingPlanId,
    }));
  };

  if (!quote) {
    return {
      isLoadingBasket,
      quote,
    };
  }

  const hasRegistrationFees = quoteHasRegistrationFees(quote);

  const registrationFees = getRegistrationFeesSubTotalForQuote(quote, taxMode);

  const hasTuition = quoteHasTuitionFees(quote);

  const tuitionFees = tuitionFeesSubTotal(
    quote,
    selectedPricingPlanIds,
    taxMode,
  );

  const allPricingPlansSelected =
    (!hasTuition ||
      quote.seasons.reduce((a, c) => {
        return (
          a &&
          (c.enabledPricingPlans.length === 1 ||
            selectedPricingPlanIds[c.season.id] !== undefined)
        );
      }, true)) ??
    false;

  const hasTrials = quoteHasTrials(quote);

  const trialFees = trialFeesSubTotal(quote, taxMode);

  const additionalFees = additionalFeesSubTotal(quote, taxMode);

  const tax = taxTotal(quote, selectedPricingPlanIds);

  const total =
    registrationFees +
    tuitionFees +
    trialFees +
    additionalFees +
    (taxMode === "exclusive" ? tax : 0);

  const subtotalExTax = total - tax;

  return {
    isLoadingBasket,
    quote,
    hasRegistrationFees,
    registrationFees,
    hasTuition,
    tuitionFees,
    allPricingPlansSelected,
    selectedPricingPlanIds,
    setSeasonPricingPlan,
    hasTrials,
    subtotalExTax,
    trialFees,
    additionalFees,
    tax,
    total,
  };
};
