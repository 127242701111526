import { FC, ReactNode } from "react";

import { SortSchema } from "@justraviga/classmanager-sdk";

import { Api } from "../../api";
import {
  DatatableAdditionalAction,
  DatatableConfiguration,
  DatatableMenuItem,
  DatatableQueryProps,
} from "../../datatable";
import { useExportAction } from "../../datatable/useExportAction";
import { formatDate } from "../../intlFormatter";
import { useStripeStatus } from "../../payments/useStripeStatus";
import {
  AggregateTransactionWithPrimaryType,
  containsCreditableItems,
  getTransactionPrimaryType,
  isAutoPayment,
} from "../../transactionUtils";
import { ImplementedTransactionActions } from "../actions/useSharedTransactionActions";
import { UseApi } from "../apiQueryFactory";
import {
  TransactionFilterFormSchema,
  useTransactionFilterForm,
} from "../formDefinitions/transactionsFilterForm";
import { TransactionAmountText } from "../transactions";
import { TransactionTile } from "../transactions/TransactionTile";

export type FamilyTransaction = AggregateTransactionWithPrimaryType & {
  id: string;
};
type FilterForm = TransactionFilterFormSchema;

export const useTransactionsDatatable = ({
  Datatable,
  api,
  useApi,
  transactionActions,
  goToFamily,
  setSheetTitle,
}: {
  Datatable: FC<{
    configuration: DatatableConfiguration<FamilyTransaction, FilterForm>;
  }>;
  api: Api;
  useApi: UseApi;
  transactionActions: ImplementedTransactionActions;
  goToFamily: (item: FamilyTransaction) => void;
  setSheetTitle: (title: string) => void;
}): {
  datatable: ReactNode;
  additionalActions: {
    title?: string;
    items: DatatableAdditionalAction[];
  };
} => {
  const { protector: stripeProtector } = useStripeStatus();

  const filterForm = useTransactionFilterForm({ useApi });

  const fetchData = async (query: DatatableQueryProps) => {
    const transactions = await api.transactions.listTransaction({
      ...query.toRequest(),
      sort: {
        date: SortSchema.Desc,
        createdAt: SortSchema.Desc,
      },
    });

    const data = transactions.data.map(transaction => ({
      ...transaction,
      id: transaction.transaction.id,
      primaryType: getTransactionPrimaryType(transaction),
    }));

    return {
      data,
      pagination: transactions.pagination,
    };
  };

  const config: DatatableConfiguration<FamilyTransaction, FilterForm> = {
    id: "transactions",
    title: "Transactions",
    hasPagination: true,
    placeholdersCount: 5,
    permissions: {
      archive: "financial:manage",
      create: "financial:manage",
      delete: "financial:delete",
      edit: "financial:manage",
      restore: "financial:manage",
    },
    createAction: transactionActions.showCustomTransactionCreateForm,
    exportAction: useExportAction("exportTransaction"),
    additionalActions: {
      title: "Transactions",
      items: [
        {
          icon: "cardOutline",
          title: "Record payment",
          protector: stripeProtector,
          onClick: () => transactionActions.showRecordPaymentForm({}),
          permission: "financial:manage",
        },
      ],
    },
    rowActions: {
      title: item => item.transaction.description ?? "",
      click: item => transactionActions.showPreview(item),
      delete: item => transactionActions.deleteOne(item),
      additionalRowActions: item => {
        const actions: DatatableMenuItem<FamilyTransaction>[] = [
          {
            label: "Go to family",
            icon: "openOutline",
            onClick: goToFamily,
            permission: "members:view",
          },
          {
            label: "Transaction details",
            icon: "listOutline",
            onClick: (item: FamilyTransaction) =>
              transactionActions.showPreview(item),
            permission: "financial:view",
          },
        ];

        if (containsCreditableItems(item)) {
          actions.push({
            title: "Actions",
            actions: [
              {
                label: "Add credit note",
                icon: "addCircleOutline",
                onClick: () =>
                  transactionActions.showCreditNoteForm(item, setSheetTitle),
                permission: "financial:manage",
              },
            ],
          });
        }

        if (isAutoPayment(item)) {
          actions.push({
            title: "Refund",
            actions: [
              {
                label: "Process refund",
                icon: "returnDownBack",
                onClick: () =>
                  transactionActions.showRefundForm(item, setSheetTitle),
                permission: "financial:manage",
              },
            ],
          });
        }

        return actions;
      },
    },
    fetchData,
    columns: [
      {
        label: "Description",
        placeholder: "tile",
        value: row => ({
          type: "custom",
          children: <TransactionTile transaction={row.item} />,
        }),
        width: "35%",
      },
      {
        label: "Date",
        placeholder: "text",
        value: row => ({
          type: "text",
          text: formatDate(row.item.transaction.date, "dayMonthYear"),
        }),
      },
      {
        label: "Debit",
        placeholder: "text",
        value: row => ({
          type: "custom",
          children: row.item.transaction.type === "debit" && (
            <TransactionAmountText transaction={row.item} />
          ),
        }),
      },
      {
        label: "Credit",
        placeholder: "text",
        value: row => ({
          type: "custom",
          children: row.item.transaction.type === "credit" && (
            <TransactionAmountText transaction={row.item} />
          ),
        }),
      },
    ],
    mobileColumn: {
      children: row => <TransactionTile transaction={row.item} />,
    },
    filterForm,
    contentPlaceholders: {
      noContent: {
        title: "No transactions yet",
        description:
          "Here you can find a summary of all charges, payments, refunds and credit notes.",
        icon: "helpCircleOutline",
      },
    },
  };

  return {
    datatable: <Datatable configuration={config} />,
    additionalActions: config.additionalActions!,
  };
};
