import {
  AggregateTransactionDetailsDto,
  TransactionDetailType,
} from "@justraviga/classmanager-sdk";

import { transactionTypeDetailMap } from "./transactionTypes";

type TransactionLineItem = {
  primaryId: string;
  primaryTitle: string;
  primaryAmount: number;
  additionalInfo: Array<TransactionLineAdditionalItem>;
  totalAmount: number;
};

type TransactionLineAdditionalItem = {
  title: string;
  amount: number;
  type: TransactionDetailType;
};

const getTaxTotal = (
  details: Array<AggregateTransactionDetailsDto>,
): number => {
  return details
    .filter(detail => detail.details.type === TransactionDetailType.Tax)
    .reduce((acc, detail) => acc + detail.details.amount, 0);
};

const getRelatedTransactionDetails = ({
  details,
  entityId,
}: {
  details: Array<AggregateTransactionDetailsDto>;
  entityId: string;
}): Array<AggregateTransactionDetailsDto> => {
  return details.filter(
    detail =>
      detail.relatedEntities.filter(e => e.entityId === entityId).length > 0,
  );
};

const isPrimaryTransactionDetail = (type: TransactionDetailType) =>
  transactionTypeDetailMap[type] !== null;

const buildAdditionalInfo = (
  relatedDetails: AggregateTransactionDetailsDto[],
): Array<TransactionLineAdditionalItem> => {
  return relatedDetails.map(relatedDetail => ({
    title: relatedDetail.details.description,
    amount: relatedDetail.details.amount,
    type: relatedDetail.details.type,
  }));
};

export const getTransactionLineItems = ({
  details,
}: {
  details: Array<AggregateTransactionDetailsDto>;
}): Array<TransactionLineItem> => {
  return details
    .filter(detail => isPrimaryTransactionDetail(detail.details.type))
    .map(detail => {
      const relatedDetails = getRelatedTransactionDetails({
        details,
        entityId: detail.details.id,
      });

      const primaryTitle = detail.details.description;

      const additionalInfo = buildAdditionalInfo(relatedDetails);
      const discountTotal = additionalInfo
        .filter(item => item.type === TransactionDetailType.Discount)
        .reduce((acc, item) => acc + Math.abs(item.amount), 0);
      const primaryAmount = detail.details.amount;

      return {
        primaryId: detail.details.id,
        primaryTitle,
        primaryAmount: primaryAmount + discountTotal,
        additionalInfo,
        totalAmount: primaryAmount + getTaxTotal([...relatedDetails, detail]),
      };
    });
};
