import {
  AggregateTransactionDetailsDto,
  AggregateTransactionDto,
  TransactionDetailType,
} from "@justraviga/classmanager-sdk";
import type { TransactionDetailRelatedEntityDto } from "@justraviga/classmanager-sdk/dist/models/TransactionDetailRelatedEntityDto";

import { CheckoutItem } from "./CheckoutItem";
import { useGenericComponents } from "../../../GenericComponentsProvider";
import { useSettings } from "../../../useSettings";

export const AggregateTransactionDetailsList = ({
  transaction,
}: {
  transaction: AggregateTransactionDto;
}) => {
  const { View } = useGenericComponents();
  const { taxMode } = useSettings("tax");

  const detailsWithoutTaxAndDiscounts = transaction.details.filter(
    detail =>
      ![
        TransactionDetailType.Tax,
        TransactionDetailType.Discount,
        TransactionDetailType.DiscountTax,
      ].includes(detail.details.type),
  );

  const getDiscountTax = (
    relatedEntities: Array<TransactionDetailRelatedEntityDto>,
  ) => {
    return transaction.details
      .filter(detail => {
        return (
          detail.details.type === TransactionDetailType.DiscountTax &&
          relatedEntities.some(e => detail.details.id === e.entityId)
        );
      })
      .reduce((acc, detail) => acc + Math.abs(detail.details.amount), 0);
  };

  const discountsTotal = transaction.details
    .filter(({ details }) => details.type === TransactionDetailType.Discount)
    .reduce((runningTotal, next) => {
      const discountTax = getDiscountTax(next.relatedEntities);

      return runningTotal + next.details.amount - discountTax;
    }, 0);

  const findItemDiscounts = (detail: AggregateTransactionDetailsDto) => {
    return transaction.details.filter(
      ({ details, relatedEntities }) =>
        details.type === TransactionDetailType.Discount &&
        relatedEntities.some(e => detail.details.id === e.entityId),
    );
  };

  const findItemTaxes = (detail: AggregateTransactionDetailsDto) => {
    return transaction.details.filter(
      ({ details, relatedEntities }) =>
        details.type === TransactionDetailType.Tax &&
        relatedEntities.some(e => detail.details.id === e.entityId),
    );
  };

  const getItemAmount = (detail: AggregateTransactionDetailsDto): number => {
    const discountItems = findItemDiscounts(detail);

    if (taxMode === "exclusive") {
      const discountTotal = discountItems.reduce((runningTotal, next) => {
        return runningTotal + next.details.amount;
      }, 0);

      return detail.details.amount + Math.abs(discountTotal);
    }

    const discountTotalWithTax = discountItems.reduce((runningTotal, next) => {
      const discountTax = getDiscountTax(next.relatedEntities);

      return runningTotal + next.details.amount - discountTax;
    }, 0);

    const taxTotal = findItemTaxes(detail).reduce(
      (runningTotal, next) => runningTotal + next.details.amount,
      0,
    );

    return detail.details.amount + Math.abs(discountTotalWithTax) + taxTotal;
  };

  return (
    <View className={"space-y-1"}>
      {detailsWithoutTaxAndDiscounts.map(detail => (
        <View key={detail.details.id} className={"space-y-1"}>
          <CheckoutItem
            text={detail.details.description}
            amount={getItemAmount(detail)}
            style={detail.details.type === "discount" ? "light" : "normal"}
          />
        </View>
      ))}
      {discountsTotal < 0 && (
        <View
          key={`${transaction.transaction.id}-discounts`}
          className={"space-y-1"}>
          <CheckoutItem
            text={"Discounts"}
            amount={discountsTotal}
            style={"light"}
          />
        </View>
      )}
    </View>
  );
};
