import { getPlatformFunctions } from "../../platformSpecific";
import { StatCard } from "../modules/common/stats/StatCard";
import { StatCardGroup } from "../modules/common/stats/StatCardGroup";

export const FamilyStats = () => {
  const { useApi } = getPlatformFunctions();
  const { data, isLoading } = useApi("getFamilyStats", {});

  return (
    <StatCardGroup
      cards={[
        <StatCard
          isLoading={isLoading}
          title={"Families on Portal"}
          bigNumber={data?.onPortal ?? 0}
        />,
        <StatCard
          isLoading={isLoading}
          title={"Families starting this month"}
          bigNumber={data?.startingThisMonth ?? 0}
        />,
        <StatCard
          isLoading={isLoading}
          title={"Families leaving this month"}
          bigNumber={data?.leavingThisMonth ?? 0}
        />,
      ]}
    />
  );
};
