import { PricingPlanDto, QuoteDto } from "@justraviga/classmanager-sdk";
import { PricingPlanType } from "@justraviga/classmanager-sdk/dist/models/PricingPlanType";

import { seasonTuitionSubTotalForPlan } from "../../../../checkoutUtils";
import { BigRadioOption } from "../../../../forms/formComponentProps";
import { formatMoneyFromInteger } from "../../../../intlFormatter";
import { numberToOrdinal } from "../../../../numberUtils";
import { BigRadio } from "../../../forms/BigRadio";
import { useSettings } from "../../../useSettings";

export const PricingPlanSelector = ({
  pricingPlans,
  seasonQuote,
  selectedPricingPlanId,
  setPricingPlanId,
}: {
  pricingPlans: PricingPlanDto[];
  seasonQuote: QuoteDto;
  selectedPricingPlanId: string | undefined;
  setPricingPlanId: (pricingPlanId: string, seasonId: string) => void;
}) => {
  const options = usePricingPlanOptions(pricingPlans, seasonQuote);

  function onChange(value: string | number | undefined) {
    const pricingPlanId = value as string;

    setPricingPlanId(pricingPlanId, seasonQuote.season.id);
  }

  return (
    <BigRadio
      onChange={onChange}
      value={selectedPricingPlanId}
      options={options}
    />
  );
};

function usePricingPlanOptions(
  pricingPlans: PricingPlanDto[],
  seasonQuote: QuoteDto,
): BigRadioOption[] {
  const { taxMode } = useSettings("tax");

  return (
    pricingPlans
      // always show monthly first
      .sort((a, _) => (a.type === "monthly" ? -1 : 1))
      .map(plan => ({
        label: pricingPlanLabel(
          plan.type,
          formatMoneyFromInteger(
            seasonTuitionSubTotalForPlan(seasonQuote, plan, taxMode),
          ),
        ),
        description: pricingPlanDescription(
          plan.type,
          seasonQuote.remainingInstalments,
          numberToOrdinal(plan.paymentDay || 0),
        ),
        value: plan.id,
        icon: "calendarOutline",
        chipLabel:
          plan.type === "one-off" && (plan.discount ?? 0) > 0
            ? `${plan.discount!}% off`
            : undefined,
        chipVariant: "success",
      }))
  );
}

function pricingPlanLabel(planType: PricingPlanType, amount: string) {
  switch (planType) {
    case "monthly":
      return `Pay monthly ${amount}`;
    case "one-off":
      return `Pay upfront ${amount}`;
    default:
      throw new Error(`Unsupported pricing plan: ${planType}`);
  }
}

function pricingPlanDescription(
  planType: PricingPlanType,
  instalments: number,
  day: string,
) {
  switch (planType) {
    case "monthly":
      return `${instalments} ${t("entity.instalments", { count: instalments })} every ${day} of the month`;
    case "one-off":
      return "Due today";
    default:
      throw new Error(`Unsupported pricing plan: ${planType}`);
  }
}
