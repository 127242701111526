/**
 * Lifted directly from https://github.com/ts-ign0re/bankers-rounding/blob/master/index.js
 *
 * Applies bankers rounding where we round to the nearest even number.
 */
export function bankersRounding(num: number, decimalPlaces: number = 0) {
  const m = 10 ** decimalPlaces;
  const n = +(decimalPlaces ? num * m : num).toFixed(8);
  const i = Math.floor(n);
  const f = n - i;
  const e = 1e-8;
  const r =
    f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);
  return decimalPlaces ? r / m : r;
}
