import { useBreakpoint } from "@shared/breakpoints";
import {
  DatatableContentState,
  DatatableRequest,
  useDatatable,
} from "@shared/datatable";

import { DatatableFilters } from "@/modules/common/datatable/common/DatatableFilters";
import { DatatableGroupActions } from "@/modules/common/datatable/common/DatatableGroupActions";
import { datatableCreateWebButtonProps } from "@/modules/common/datatable/utilities";
import { SearchInput } from "@/modules/common/form/SearchInput";
import { ActionMenu } from "@/modules/common/ui/ActionMenu";
import { Button } from "@/modules/common/ui/button/Button";
import { IconButton } from "@/modules/common/ui/button/IconButton";
import { ProtectedOverlay } from "@/modules/common/ui/ProtectedOverlay";

export const WebHeader = ({
  contentState,
}: {
  contentState: DatatableContentState;
}) => {
  const { datatable, setSearch, query } = useDatatable();

  const showControls =
    datatable.hasSearch !== false &&
    (contentState === DatatableContentState.Loaded ||
      contentState === DatatableContentState.Loading ||
      contentState === DatatableContentState.NoResults);

  const additionalActions = datatable.additionalActions;

  const largeTitle =
    typeof datatable.title === "string" ? (
      <h5 className={"pb-6 text-heading5-600"}>{datatable.title}</h5>
    ) : (
      datatable.title
    );

  const smallTitle =
    typeof datatable.title === "string" ? (
      <span className={"mr-4 text-heading6-600"}>{datatable.title}</span>
    ) : (
      datatable.title
    );

  return (
    <div>
      {showControls && datatable.title ? largeTitle : null}

      {datatable.afterTitle && (
        <div className="mb-4">{datatable.afterTitle}</div>
      )}

      <section className={"flex justify-between"}>
        <div className={"flex items-center justify-start"}>
          {!showControls && datatable.title && smallTitle}

          {showControls && (
            <div className={"mr-4"} data-testid="datatable-search-input">
              <SearchInput onChange={setSearch} />
            </div>
          )}
          {showControls && (
            <div>
              <DatatableFilters />
            </div>
          )}
        </div>

        <div className={"flex items-center gap-x-4"}>
          <div>
            <SelectedRowsText />
          </div>

          {showControls && (
            <div>
              <DatatableGroupActions />
            </div>
          )}

          <ExportButton
            exportAction={datatable.exportAction}
            request={query?.toRequest()}
          />

          {datatable.createAction &&
            contentState !== DatatableContentState.NoContent && (
              <div className={"flex space-x-4"}>
                {additionalActions && (
                  <div className={"hidden md:flex"}>
                    <ActionMenu
                      width={"w-80"}
                      header={
                        additionalActions?.title && (
                          <h3 className={"text-heading6-600 text-grey-900"}>
                            {additionalActions.title}
                          </h3>
                        )
                      }
                      trigger={
                        <Button
                          text={"Actions"}
                          role="datatable-additional-actions"
                          rightIcon={"chevronDown"}
                          variant={"secondary"}
                          size={"sm"}
                        />
                      }
                      items={additionalActions.items.map(action => ({
                        title: action.title,
                        onClick: action.onClick,
                        protector: action.protector,
                        permission: action.permission,
                        leftIcon: action.icon,
                        group: action.items
                          ? {
                              items: action.items.map(item => ({
                                title: item.title,
                                onClick: item.onClick,
                                leftIcon: item.icon,
                                permission: item.permission,
                              })),
                            }
                          : undefined,
                      }))}
                    />
                  </div>
                )}

                {datatable.navigationAction && (
                  <div className={"hidden md:flex"}>
                    <ProtectedOverlay
                      permission={datatable.navigationAction.permission}>
                      <Button
                        text={datatable.navigationAction.text}
                        onClick={datatable.navigationAction.onClick}
                        variant={"secondary"}
                        size={"sm"}
                        className={"underline-offset-2"}
                      />
                    </ProtectedOverlay>
                  </div>
                )}

                <div className={"hidden md:flex"}>
                  <ProtectedOverlay permission={datatable.permissions?.create}>
                    <Button {...datatableCreateWebButtonProps(datatable)} />
                  </ProtectedOverlay>
                </div>
              </div>
            )}
        </div>
      </section>
    </div>
  );
};

const ExportButton = ({
  exportAction,
  request,
}: {
  exportAction?: (request: DatatableRequest) => void;
  request?: DatatableRequest;
}) => {
  const { lg: largeScreen } = useBreakpoint();

  if (!exportAction || !request) {
    return null;
  }

  return (
    <div>
      {largeScreen ? (
        <Button
          variant="secondary"
          onClick={() => exportAction(request)}
          size="sm"
          text="Export"
        />
      ) : (
        <IconButton
          icon="downloadOutline"
          variant="secondary-outline"
          onClick={() => exportAction(request)}
          size="sm"
        />
      )}
    </div>
  );
};

const SelectedRowsText = () => {
  const { modelSelection } = useDatatable();
  const numberOfSelectedRows = modelSelection.count();

  if (numberOfSelectedRows === 0) {
    return null;
  }

  return (
    <div className={"text-base text-grey-900"}>
      <span className={""}>{numberOfSelectedRows.toString()} selected</span>
    </div>
  );
};
