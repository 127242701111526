import { PropsWithChildren } from "react";

import { ListFeatureFlagResource } from "@justraviga/classmanager-sdk";

import { FeatureFlagContext } from "./featureFlagContext";
import { useAuthState } from "../../../auth/useAuthState";
import { getPlatformFunctions } from "../../../platformSpecific";

// convenience until I figure out a fancy way to get this named from the backend
type FeatureFlag = ListFeatureFlagResource["data"][number];

function useListFeatureFlags() {
  const { account } = useAuthState();
  const { useApi } = getPlatformFunctions();
  const { data } = useApi(
    "listFeatureFlags",
    {},
    {
      enabled: !!account?.company,
    },
  );

  // Always return a list
  return data?.data ?? [];
}

export const FeatureFlagProvider = ({ children }: PropsWithChildren) => {
  const featureFlags = useListFeatureFlags();

  function findFeatureByName(featureName: string): FeatureFlag {
    const featureFlag = featureFlags!.find(
      feature => feature.name === featureName,
    );

    if (featureFlag === undefined) {
      // todo not sure how we should handle this but the response from the backend only includes features that have been defined there
      return {
        id: "ff_NOT_FOUND",
        name: featureName,
        globallyEnabled: false,
        adminEnabled: false,
        companyEnabled: false,
      };
    }

    return featureFlag;
  }

  function featureGloballyEnabled(featureName: string): boolean {
    return findFeatureByName(featureName).globallyEnabled;
  }

  function featureEnabled(featureName: string): boolean {
    const feature = findFeatureByName(featureName);

    return (
      featureGloballyEnabled(featureName) &&
      feature.adminEnabled &&
      feature.companyEnabled
    );
  }

  const value = {
    featureGloballyEnabled,
    featureEnabled,
  };

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
