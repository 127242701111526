import { dateSubtract, dateToday } from "../../../../dateUtils";
import { getPlatformFunctions } from "../../../../platformSpecific";
import { withPermissionCheck } from "../../../permission/withPermissionCheck";
import { StatCard } from "../../common/stats/StatCard";

const numDays = 28;

const TotalFamiliesWidget = () => {
  const { useApi } = getPlatformFunctions();

  const { data: total, isLoading: isLoadingTotal } = useApi(
    "countFamily",
    {},
    {},
  );

  const { data: recent, isLoading: isLoadingRecent } = useApi("countFamily", {
    where: {
      createdAt: {
        greaterThanOrEqual: dateSubtract(dateToday(), numDays, "day"),
      },
    },
  });

  const isLoading = isLoadingTotal || isLoadingRecent;

  return (
    <StatCard
      isLoading={isLoading}
      title={"Total families"}
      bigNumber={total?.count ?? 0}
      trend={{
        direction: "up",
        description: `${recent?.count ?? 0} in last ${numDays} days`,
      }}
    />
  );
};

const PermissionChecked = withPermissionCheck(
  "financial:view",
  TotalFamiliesWidget,
);
export { PermissionChecked as TotalFamiliesWidget };
