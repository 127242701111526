import { CheckoutItem } from "./CheckoutItem";
import { CheckoutSection } from "./CheckoutSection";
import { useGenericComponents } from "../../../GenericComponentsProvider";

interface Props {
  additionalFees: number;
  allPricingPlansSelected: boolean;
  total: number;
  registrationFees: number;
  tax: number;
  trialFees: number;
  tuitionFees: number;
  subtotalExTax: number;
}

export const PaymentSummary = ({
  additionalFees,
  allPricingPlansSelected,
  total,
  registrationFees,
  tax: taxRaw,
  trialFees,
  tuitionFees: tuitionFeesRaw,
  subtotalExTax,
}: Props) => {
  const { HorizontalSeparator, View } = useGenericComponents();

  const tuitionFees = allPricingPlansSelected ? tuitionFeesRaw : null;
  const tax = allPricingPlansSelected ? taxRaw : null;

  return (
    <CheckoutSection spacing={"tight"}>
      {registrationFees > 0 && (
        <View>
          <CheckoutItem
            text={"Registration fees"}
            style={"light"}
            amount={registrationFees}
          />
        </View>
      )}
      {tuitionFees !== 0 && (
        <View>
          <CheckoutItem
            text={"Tuition fees"}
            style={"light"}
            amount={tuitionFees}
          />
        </View>
      )}
      {additionalFees > 0 && (
        <View>
          <CheckoutItem
            text={"Additional fees"}
            style={"light"}
            amount={additionalFees}
          />
        </View>
      )}
      {trialFees > 0 && (
        <View>
          <CheckoutItem
            text={"Trial Fees"}
            style={"light"}
            amount={trialFees}
          />
        </View>
      )}

      <HorizontalSeparator spacing={2} />

      <View>
        <CheckoutItem
          text={"Subtotal (excl tax)"}
          style={"light"}
          amount={subtotalExTax}
        />
      </View>
      <View>
        <CheckoutItem text={"Total tax"} style={"light"} amount={tax} />
      </View>

      <View>
        <CheckoutItem text={"Pay today"} style={"big"} amount={total} />
      </View>
    </CheckoutSection>
  );
};
