import { useGenericComponents } from "../GenericComponentsProvider";

export const FileDownloadingAlert = () => {
  const { View, Text, Loader } = useGenericComponents();
  return (
    <View className="flex flex-row items-center space-x-4">
      <Loader />
      <Text className="text-label-400 text-grey-900">
        Your download will begin shortly....
      </Text>
    </View>
  );
};
